import styles from './Loading.module.scss';

export default function Loading() {
    return (
        <div className={styles.backdrop}>
            <div className={styles.loading}>
                <img src='/favicon-32x32.png' />
            </div>
        </div>
    )
}