import { useTranslation } from "react-i18next";
import styles from './App.module.scss';
import "./styles/globals.scss";

import { faCamera, faCog, faPlug, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from "react";
import { Outlet, useParams } from 'react-router-dom';
import Aquarium from "./components/Aquarium/Aquarium.jsx";
import ErrorBoundary from "./components/ErrorBoundary.jsx";
import { MenuItem } from './components/MenuHeader/Menu/Menu.jsx';
import MenuHeader from './components/MenuHeader/MenuHeader.jsx';
import md from "./lib/MobileDetect.js";
import useMenuStore from "./store/menuStore.js";
import useRouter from "./store/useRouter.js";
import useUserStore from "./store/userStore.js";

export default function App({openMenu, aquariumPath}) {
    const { t } = useTranslation();
    const { id, connectToken } = useParams();
    const inputRef = useRef();
    const setBase = useRouter(state => state.setBase);
    const serverUser = useUserStore(state => state.serverUser);
    const setVisible = useMenuStore(state => state.setVisible);
    const [menuWasOpened, setMenuWasOpened] = useState();

    useEffect(() => {
        if (id && aquariumPath) {
            setBase(`/aquarium/${id}`);
        }
        else if (connectToken) {
            setBase(`/c/${connectToken}`);
        }
    }, [id, connectToken])

    useEffect(() => {
        if (menuWasOpened) return;
        setVisible(openMenu);
        setMenuWasOpened(true)
    }, [openMenu, menuWasOpened])

    const uploadMenuItem = (md.mobile() || md.tablet()) ? 
    <MenuItem key='capture' linkTo="/capture"><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faCamera} size="2x" /></div> {t('scan')}</MenuItem> :
    <MenuItem key='upload' linkTo="/upload"><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faCamera} size="2x" /></div> {t('upload')}</MenuItem>

    const menuItems = [
        uploadMenuItem,
        <MenuItem key='print' linkTo={"/print"}><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faPrint} size="2x" /></div> {t('print')}</MenuItem>,
        <MenuItem key='share' linkTo={"/share"}><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faPlug} size="2x" /></div> {t('share')}</MenuItem>
    ];

    if (serverUser) {
        menuItems.push (
            <MenuItem key='account' linkTo={"/account"}><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faCog} size="2x" /></div> {t('accountSettings')}</MenuItem>
        )
    }

    return (
        <div className="App">
            <ErrorBoundary>
                <MenuHeader menuItems={menuItems} />
                <Aquarium id={id} connectToken={connectToken} aquariumPath={aquariumPath}/>

                {/* see main.js for router */}
                <Outlet /> 
            </ErrorBoundary>
        </div>
    )
}