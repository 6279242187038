const tapSound = new Audio('/assets/audio/tap.mp3');
const waterDrop = new Audio('/assets/audio/water-drop.mp3');
const swooshSound = new Audio('/assets/audio/swoosh.mp3');
const successSound = new Audio("/assets/audio/success1.mp3");
const paperSound = new Audio("/assets/audio/paper-sound.mp3");
const wowSound = new Audio("/assets/audio/level-up.mp3");

const Sound = {
    tap: () => tapSound.play(),
    waterDrop: () => waterDrop.play(),
    swoosh: () => swooshSound.play(),
    success: () => successSound.play(),
    paper: () => paperSound.play(),
    wow: () => wowSound.play()
}

export default Sound;