import { useToast } from "@chakra-ui/react";
import { faPlug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import Sound from "../../lib/sound.js";
import useFishyStore from "../../store/fishys.js";
import useHalloweenStore from "../../store/halloweenStore.js";
import AnimatedFish from "./AnimatedFish/AnimatedFish.jsx";
import styles from "./Aquarium.module.scss";
import Bubbles from "./Bubbles/Bubbles.jsx";
import FullScreen from "./FullScreen/FullScreen.jsx";

const waterSplashSound = new Audio('/assets/audio/water-splosh.mp3');

function Aquarium ({id, connectToken, aquariumPath}) {
    const [fishys, serverFishys, internalConnectToken, aquariumId, checkAndSetAquariumId, checkAndSetConnectToken, connectedToOwnAquarium] = useFishyStore((state) => [
        state.fishys, state.serverFishys, state.connectToken, state.aquariumId, state.checkAndSetAquariumId, state.checkAndSetConnectToken, state.connectedToOwnAquarium]);
    const [mergedFishys, setMergedFishys] = useState([]);
    const fishysCount = useRef(fishys.length);
    const aquariumRef = useRef();
    const toast = useToast()
    const {t} = useTranslation();
    const halloweenFishyUploaded = useHalloweenStore(state => state.halloweenFishyUploaded);
    const url = useMemo (() => `${window.location.origin}/c/${internalConnectToken}`, [internalConnectToken])

    //connect to an aquarium
    useEffect(() => {
        (async () => {
            if (id && aquariumPath) {
                const exists = await checkAndSetAquariumId(id);
                if (exists) {
                    toast({
                        description: t('connectedToAquariumSuccess', {aquariumId: id}),
                        status: 'success',
                        duration: 4000,
                      })
                }
                else {
                    toast({
                        description: t('connectedToAquariumFail', {aquariumId: id}),
                        status: 'error',
                        duration: 4000,
                      })
                }
            }
            else if (connectToken) {
                const exists = await checkAndSetConnectToken(connectToken);
                if (exists) {
                    toast({
                        description: t('connectedToAquariumSuccess', {aquariumId: connectToken}),
                        status: 'success',
                        duration: 4000,
                      })
                }
                else {
                    toast({
                        description: t('connectedToAquariumFail', {aquariumId: connectToken}),
                        status: 'error',
                        duration: 4000,
                      })
                }
            }
        })()
    }, [])

    useEffect(() => {

        const newMergedFishys = structuredClone([...fishys, ...serverFishys]);

        if (newMergedFishys.length > fishysCount.current) {
            waterSplashSound.play();
            Sound.wow();
            fishysCount.current = newMergedFishys.length;
        }

        newMergedFishys.forEach (newFishy => {
            if (!mergedFishys.some(oldFishy => oldFishy.fileUrl === newFishy.fileUrl)){
                newFishy.isNew = true;
            }
        });

        setMergedFishys(newMergedFishys);
    }, [fishys, serverFishys]);

    return (
        <div className={`${styles.aquarium} ${halloweenFishyUploaded ? styles.invert : ''}`} ref={aquariumRef}>
            {id || !internalConnectToken ? null :
                <div className={styles.aquariumId}><FontAwesomeIcon icon={faPlug} />{internalConnectToken}</div>
            }
            <Bubbles />
            {mergedFishys.map (fishy => 
                <AnimatedFish   fileUrl={fishy.fileUrl} 
                                maskUrl={fishy.maskUrl} 
                                key={fishy.fileUrl} 
                                focusable={id || aquariumPath || (internalConnectToken && connectedToOwnAquarium)}
                                isNew={fishy.isNew}
                />
            )}
            {!id || !aquariumPath || !internalConnectToken ? null : 
                <QRCode value={url}  className={styles.qrCode}/>
            }
            <FullScreen aquariumRef={aquariumRef.current}/>
        </div>
    )
}

export default Aquarium;
