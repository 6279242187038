import { useEffect, useRef } from "react";

function Keyframes({ animations, children }) {

    const keyframesStylesheetRef = useRef();

    useEffect(() => {

        if (!animations) return () => {};

        const keyframes = animations.reduce((accum, {animationName, keyframes}) => accum += `
        @keyframes ${animationName}{
            ${Object.entries(keyframes).reduce((accum, [step, obj]) => {
                return accum += `${step} {
                ${Object.entries(obj).reduce((props, [key, value]) => props += `${key}: ${value};\n`, '')}
                }`
            }, '')}
        }\n`, '');


        if (!keyframesStylesheetRef?.current) {
            const stylesheet = new CSSStyleSheet();
            stylesheet.replace(keyframes);
            document.adoptedStyleSheets = [...document.adoptedStyleSheets, stylesheet];
            keyframesStylesheetRef.current = stylesheet;
        }
        else {
            keyframesStylesheetRef.current.replace(keyframes);
        }

        
        return () => {
            document.adoptedStyleSheets = document.adoptedStyleSheets.filter (as => as !== keyframesStylesheetRef.current);
            keyframesStylesheetRef.current = null;
        };

    }, [animations])

    return ([children])
}

export default Keyframes;
